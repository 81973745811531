import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/** Provide functionality to create a link to a chat. */
@Injectable()
export abstract class ChatLinkService {

	/** Generate chat link. */
	public abstract generateChatLink(): Observable<string>;
}
